<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    persistent
    width="340px"
    :disabled="disabled">
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="timeFormat"
        prepend-inner-icon="mdi-clock-time-three-outline"
        placeholder="00 : 00"
        suffix="น."
        readonly
        outlined
        dense
        hide-details
        :rules="rules"
        :disabled="disabled"
        v-bind="attrs"
        v-on="on">
      </v-text-field>
    </template>
    <v-time-picker
      v-model="timeData"
      format="24hr">
      <v-spacer />
      <v-btn
        text
        color="primary"
        @click="onCancel()">
        ยกเลิก
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="onConfirm()">
        ยืนยัน
      </v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: {
    value: {
      type: String,
      default: null
    },
    rules: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    modal: false,
    time: dayjs().format('YYYY-MM-DD HH:mm')
  }),
  computed: {
    timeFormat () {
      if (!this.value) {
        return ''
        // return this.$dayjs().locale('th').format('HH:mm')
      }

      return this.$dayjs(this.value).locale('th').format('HH:mm')
    },
    timeData: {
      get () {
        if (!this.time) {
          return this.$dayjs().format('HH:mm')
        }

        return this.$dayjs(this.time).format('HH:mm')
      },
      set (val) {
        const rawDate = this.time ? this.$dayjs(this.time).format('YYYY-MM-DD') : this.$dayjs().format('YYYY-MM-DD')

        this.time = this.$dayjs(`${rawDate} ${val}`).format('YYYY-MM-DD HH:mm')
      }
    }
  },
  mounted () {
    if (!this.value) {
      // this.time = this.$dayjs().format('YYYY-MM-DD HH:mm')
      this.time = ''
    } else {
      this.time = this.$dayjs(this.value).format('YYYY-MM-DD HH:mm')
    }
  },
  methods: {
    onCancel () {
      this.modal = false

      if (!this.value) {
        this.time = this.$dayjs().format('YYYY-MM-DD HH:mm')
      } else {
        this.time = this.$dayjs(this.value).format('YYYY-MM-DD HH:mm')
      }
    },
    onConfirm () {
      if (!this.value) {
        const rawDate = this.$dayjs(this.time).format('YYYY-MM-DD')

        this.$emit('input', this.$dayjs(`${rawDate} ${this.timeData}`).toISOString())
      } else {
        const rawDate = this.$dayjs(this.value).format('YYYY-MM-DD')

        this.$emit('input', this.$dayjs(`${rawDate} ${this.timeData}`).toISOString())
      }

      this.modal = false
    }
  }
}
</script>

<style scoped>

</style>
