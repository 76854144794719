<template>
  <VueEditor
    v-model="formData"
    :editor-toolbar="customToolbar"
    :disabled="disabled"
    :custom-modules="[]" />
</template>

<script>
import { Quill } from 'vue2-editor'

const size = Quill.import('attributors/style/size')
size.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '28px', '30px', '32px', '36px']
Quill.register(size, true)

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    customModules: [
      {
        alias: 'size',
        module: size
      }
    ],
    customToolbar: [
      [
        {
          size: [false, '10px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '28px', '30px', '32px', '36px']
        }
      ],
      ['bold', 'italic', 'underline', 'strike'],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' }
      ],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { list: 'check' }
      ],
      [
        { indent: '-1' },
        { indent: '+1' }
      ],
      [
        {
          color: [
            '#026EAA',
            '#333333',
            '#FFFFFF',
            '#07BAC6',
            '#27AE60',
            '#FFDF7D',
            '#EB5757',
            '#DED6C8'
          ]
        },
        { background: [] }
      ],
      ['clean']
    ]
  }),
  computed: {
    formData: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style>
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value]::before {
  content: attr(data-value) !important;
}
</style>
